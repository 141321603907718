import React, { Component, createRef } from "react";
import { Button, Col, Row, Modal, Container, Nav, Form } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import MaterialTable from "material-table";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { req } from "../utils/request.js";
import FS from "../components/FieldStructure.js";
import { withRouter } from "react-router-dom";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import Alerts from "../common/Alerts.js";
import Audits from "../components/Audits.js";
import $ from "jquery";
import MultiSelect from "../components/MultiSelect.js";
import { API_URL } from "../utils/config.js";
import { getTrueFalse, checkStatus } from "../components/functions.js";
import HistoryIcon from "@mui/icons-material/History";
import { ROLE_SYSTEM_ADMIN } from "../utils/roles.js";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import { Prompt } from "react-router-dom/cjs/react-router-dom.min.js";
import { base64ToBlob } from '../utils/file';
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import ConfirmationDialog from "../components/ConfirmationDialog.jsx";
import DeleteIcon from '@mui/icons-material/Delete';
import toast from "react-hot-toast";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { CircularProgress } from "@material-ui/core";
import pLimit from 'p-limit';

const { sessionStorage } = window;
class PollFormsCreateEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      popupAddForm: false,
      forms: [],
      popupAddSection: false,
      sectionsFiltered: [],
      fieldresponses: [],
      dependentfieldsSelected: [],
      formtypes: [],
      allForms: [],
      opcionesRequisito: [],
      editingData: [],
      editingDataSection: [],
      formfieldsAll: [],
      alertShow: false,
      alertMessage: "",
      alertType: "",
      auditType: "",
      auditID: 0,
      dependence_type: "",
      isCreatingQuestion: false,
      leaveValidation: true,
      showMoveQuestion: false,
      questionToMove: null,
      showAudit: false,
      sections: [],
      questions: [],
      confirmModal: null,
      isAdmin: false,
      confirmModal2: null,
      confirmModal3: null,
      loadingQuestions: false
    };
    this.show_PopUpSections = this.show_PopUpSections.bind(this);
    this.hide_PopUpSections = this.hide_PopUpSections.bind(this);
    this.hideModalQuestion = this.hideModalQuestion.bind(this);
    this.closeAudit = this.closeAudit.bind(this);
    this.fetchSections = this.fetchSections.bind(this);

    this.tableRef = createRef();
    this.editRowSections = this.editRowSections.bind(this);
    this.desactivateRowSections = this.desactivateRowSections.bind(this);
    this.activeRowSections = this.activeRowSections.bind(this);
    this.fetchQuestions = this.fetchQuestions.bind(this);
    this.processDependency = this.processDependency.bind(this);
  }

  hideModalQuestion() {
    this.setState({
      showModalRemoveDependence: false,
      showMoveQuestion: false,
    });
  }
  showMoveQuestion(id) {
    req
      .get(`${API_URL}formularios/items/${id}/show`)
      .then((response) => {
        let dependenciasActivas = response.data.data.dependencias.filter(dependencia => dependencia.activo === true);

        if (dependenciasActivas.length == 0) {
          this.setState({
            showMoveQuestion: true,
            questionToMove: response.data.data
          });
        } else {
          this.setState({
            showModalRemoveDependence: true,
          });
        }
      })

    // this.setState({
    //   showMoveQuestion: true,
    //   questionToMove: id,
    // });
  }

  formSelected(formik) {
    const formulario_id = formik.values.formulario_id;
    formik.setFieldValue('section_id', ''); // reset selected section
    req
      .get(`${API_URL}formularios/secciones`, {
        params: {
          formulario: formulario_id,
          estado: 'activos',
          paginacion: 999999,
        },
      })
      .then((response) => {
        this.setState({
          sectionsFiltered: response.data.data ?? [],
        });
      });
    // update questions field
    req
      .get(`${API_URL}formularios/items`, {
        params: {
          formulario: formulario_id,
          estado: 'activo',
          paginacion: 9999,
        },
      })
      .then((response) => {
        const allQuestions = response.data.data ?? [];
        this.setState({
          isLoaded: true,
          formfieldsAll: allQuestions,
          selectableQuestions: allQuestions.filter(
            (elem) => elem.id != this.props.match.params.fieldId
          ),
        });
      });
  }
  fetchSections(fieldId) {
    req
      .get(`${API_URL}formularios/${fieldId}/show`, {
        params: { paginacion: 999999 },
      })
      .then((response) => {
        this.setState({
          editingData: response.data.data,
          sections: response.data.data.secciones,
        });
        sessionStorage.setItem(
          "formCreation",
          JSON.stringify(response.data.data.id)
        );
      });
  }

  fetchQuestions(fieldId) {
    this.setState({
      loadingQuestions: true,
    });

    req
      .get(`${API_URL}formularios/items`, {
        params: {
          formulario: fieldId,
          paginacion: 999999,
        },
      })
      .then((response) => {
        this.setState({
          questions: response.data.data ?? response.data,
        });
      })
      .finally(() => {
        this.setState({
          loadingQuestions: false,
        });
      });
  }

  componentDidMount() {
    const auth = JSON.parse(localStorage.getItem("user"));
    if (auth === undefined || auth.user?.role?.nombre !== ROLE_SYSTEM_ADMIN) {
      this.props.history.push("/");
    }

    const fieldId = this.props.match.params.formularyId;
    this.setState({
      formCreation: JSON.parse(sessionStorage.getItem("formCreation")),
      isAdmin: auth.user?.role?.nombre === ROLE_SYSTEM_ADMIN
    });

    if (fieldId !== undefined) {
      //Editing
      this.fetchSections(fieldId);
      this.fetchQuestions(fieldId);

      // req.get(`${API_URL}formularios`, { params }).then(response => {
      //   this.setState({ allForms: response.data.data });
      //   resolve({
      //     data: response.data.data,
      //     page: response.data.meta.current_page - 1,
      //     totalCount: response.data.meta.total
      //   })
      // });
    }

    req
      .get(`${API_URL}tipos/formulario`, {
        params: { estado: "activos", paginacion: 999999 },
      })
      .then((response) => {
        this.setState({ formtypes: response.data.data ?? [] });
      });
    this.requiredForm([]);

    req
      .get(`${API_URL}formularios`, {
        params: { estado: 'activos', paginacion: 999999 },
      })
      .then((response) => {
        this.setState({
          forms: response.data.data ?? [],
        });
      });
  }

  showAudit(id, type) {
    this.setState({
      showAudit: id,
      auditType: type,
    });
  }
  closeAudit() {
    this.setState({
      showAudit: null,
    });
  }
  toggleDependence(rowData) {
    const idx = this.state.dependentfieldsSelected.findIndex(data => data.id === rowData.id);
    if (idx < 0) {
      // do nothing
      return;
    }
    const elem = this.state.dependentfieldsSelected[idx];
    req.post(`${API_URL}formularios/secciones/dependencias/${elem.id}/toggle`).then(() => {
      elem.activo = !elem.activo;
      this.setState({
        dependentfieldsSelected: [...this.state.dependentfieldsSelected]
      });
    });
  }
  async addDependence(values) {

    const getOperador = () => {
      // if does exists an item
      // if (!!this.state.dependentfieldsSelected.length) {
      return `${values.dependence_operator}|${values.dependence_logic_operator}`
      // }

      // return values.dependence_operator;
    }

    const operador = getOperador();

    if (values.dependence_formfield === "") {
      return false;
    }
    if (values.dependence_operator === "") {
      return false;
    }
    if (values.dependence_response === "") {
      return false;
    }
    const respuesta = this.state.fieldresponses.find(
      (resp) => resp.id === parseInt(values.dependence_response)
    );
    const valor = this.renderAnswer({
      pregunta_id: values.dependence_formfield,
      respuesta: respuesta,
      respuesta_id: values.dependence_response,
    });
    const newDependence = {
      valor,
      formulario_respuesta: respuesta?.id,
      formulario_seccion: "", // to be set right before sending the post request to save
      form_field_id: values.dependence_formfield,
      operador: operador,
      activo: true,
    }

    // this is to force update the operator of all created dependences
    const limit = pLimit(1);
    console.log({ seccion: this.state.editingDataSection });
    await Promise.all(this.state.dependentfieldsSelected.map(elem => limit(() =>
      this.processDependency(({ ...elem, operador }),
        { data: { data: this.state.editingDataSection } }))));


    // updating ui with new dependences 
    req
      .get(`${API_URL}formularios/secciones/dependencias`, {
        params: { seccion: this.state.editingDataSection.id },
      })
      .then((response) => {
        const data = response.data.data || response.data;
        this.setState({
          dependentfieldsSelected: [...data.map((dep) => ({
            id: dep.id,
            form_field_id: dep.formulario_item_id_dependencia,
            operador: dep.operador,
            respuesta: dep.respuesta?.respuesta ?? dep.valor,
            valor: dep.valor,
            activo: dep.activo,
            originalObject: dep
          })), newDependence],
        });

        // reset values
        values.dependence_response = "";
        values.dependence_formfield = "";
        values.dependence_operator = "";
        values.dependence_logic_operator = "";
      });

  }
  renderAnswer(rowData) {
    const { pregunta_id, respuesta, respuesta_id } = rowData;
    const pregunta = this.state.formfieldsAll.find(
      (elem) => elem.id === pregunta_id) ?? { tipo_pregunta: { nombre: "" } };
    const { tipo_pregunta } = pregunta;

    if (
      tipo_pregunta.nombre.toLowerCase() === "selección múltiple" ||
      tipo_pregunta.nombre.toLowerCase() === "selección única"
    ) {
      return respuesta?.respuesta;
    }
    if (tipo_pregunta.nombre.toLowerCase() === "si o no") {
      return respuesta_id === "1" ? "Si" : "No";
    }
    if (tipo_pregunta.nombre.toLowerCase() === "verdader o falso") {
      return respuesta_id === "1" ? "Verdadero" : "Falso";
    }
  }
  selectDependence_formfield(value, formik) {
    const pregunta = this.state.questions.find((elem) => elem.id === parseInt(value));
    const tipo_pregunta = pregunta?.tipo_pregunta?.nombre?.toLowerCase();
    this.setState({
      dependence_type: tipo_pregunta,
    });
    req
      .get(`${API_URL}formularios/respuestas`, {
        params: { formulario_item: pregunta?.id, paginacion: 999999 },
      })
      .then((response) => {
        this.setState({
          fieldresponses: (response.data.data ?? []).filter((r) => r.activo),
        });
      });
    formik.setFieldValue("dependence_formfield", parseInt(value));
    formik.setFieldValue("dependence_response", "");
  }
  hide_PopUpSections() {
    this.setState({ popupAddSection: false });
  }
  show_PopUpSections(data) {

    const isNew = data.id === undefined;
    if (isNew) {
      this.setState({
        dependentfieldsSelected: [],
      });
    } else {
      req
        .get(`${API_URL}formularios/secciones/dependencias`, {
          params: { seccion: data.id },
        })
        .then((response) => {
          const data = response.data.data || response.data;
          this.setState({
            dependentfieldsSelected: data.map((dep) => ({
              id: dep.id,
              form_field_id: dep.formulario_item_id_dependencia,
              operador: dep.operador,
              respuesta: dep.respuesta?.respuesta ?? dep.valor,
              valor: dep.valor,
              activo: dep.activo,
              originalObject: dep
            })),
          });
        });

    }
    req
      .get(`${API_URL}formularios/items`, {
        params: {
          formulario: this.props.match.params.formularyId,
          paginacion: 999999,
          estado: "activos",
        },
      })
      .then((response) => {
        this.setState({
          formfieldsAll: response.data.data || response.data,
        });
      });
    this.setState({ popupAddSection: true, editingDataSection: data });
  }
  requiredForm(data) {
    req.get(`${API_URL}selectores/formularios`).then((response) => {
      this.setState({
        opcionesRequisito: [
          { label: "Ninguno", value: null },
          ...response.data.data
            .filter((elem) => elem.id !== data.id && elem.requisito === null)
            .map((elem) => ({
              label: elem.nombre,
              value: elem.id,
            })),
        ],
      });
    });
  }
  async processDependency(elem, seccion) {
    if (elem.id !== undefined) {

      // edit existing dependency
      await req.post(
        `${API_URL}formularios/secciones/dependencias/${elem.id}/update`,
        {
          formulario_seccion: seccion.data.data.id,
          formulario_item_dependencia: elem.form_field_id,
          formulario_respuesta: elem?.respuesta?.id || elem.formulario_respuesta,
          operador: elem.operador,
          valor: elem.valor
        }
      );
    } else {
      const payload = {
        formulario_seccion: seccion.data.data.id,
        formulario_item_dependencia: elem.form_field_id,
        formulario_respuesta: elem.formulario_respuesta,
        operador: elem.operador,
        valor: elem.valor,
      }
      console.log("tratando de procesar dependencia: ", payload);

      // save new dependency
      const x = await req.post(
        `${API_URL}formularios/secciones/dependencias/store`,
        payload
      );

      console.log({ x });
    }
  }

  render() {
    const {
      popupAddSection,
      opcionesRequisito,
      formtypes,
      editingData,
      editingDataSection,
      dependentfieldsSelected,
      isCreatingQuestion,
      forms,
      formfieldsAll,
      alertMessage,
      questions,
      alertShow,
      leaveValidation,
      alertType,
    } = this.state;
    console.log({ dependentfieldsSelected })
    const addSectionValidationSchema = Yup.object().shape({
      nombre: Yup.string().required("El nombre es un campo requerido."),
      tipo: Yup.string().required("El tipo es un campo requerido."),
    });
    const addFormValidationSchema = Yup.object().shape({
      nombre: Yup.string().required("El nombre es un campo requerido."),
      tipo: Yup.string().required("Tipo es un campo requerido."),
      popup: Yup.number(),
      popup_titulo: Yup.string(),
      popup_cuerpo: Yup.string(),
      logo: Yup.string().nullable(),
      requisito: Yup.string().nullable(),

    });
    const formularioRequisitoSeleccionado = opcionesRequisito.find(
      (elem) => elem.value === editingData.requisito
    );
    const fieldId = this.props.match.params.formularyId;


    return (
      <Container>
        <Prompt
          when={!isCreatingQuestion && leaveValidation}
          message="Estás seguro de que deseas dejar la página? Tus cambios podrían no ser guardados!"
        />
        <ConfirmationDialog
          onConfirm={() => {
            req.delete(`${API_URL}formularios/secciones/dependencias/${this.state.confirmModal.id}/delete`)
              .then(() => {
                this.show_PopUpSections();
                toast.success('Pregunta relacionada con esta sección eliminada correctamente');
              }).catch((error) => {
                toast.error(error?.response?.data?.message || 'Error al eliminar la pregunta relacionada con esta sección');
              }).finally(() => {
                this.setState({ confirmModal: false });
              })
          }}
          onHide={() => {
            this.setState({ confirmModal: false });
          }}
          show={!!this.state.confirmModal}
        >
          <p className='text-center'>
            ¿Te gustaría eliminar la pregunta relacionada con esta sección?
          </p>

        </ConfirmationDialog>
        {this.state.showAudit && (
          <Audits
            close={this.closeAudit}
            show={!!this.state.showAudit}
            tipo={this.state.auditType}
            id={this.state.showAudit}
          />
        )}
        <div className="titleButtons">
          <Nav className="justify-content-end">
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <Formik
              initialValues={{
                nombre: editingData.nombre || "",
                tipo: editingData.tipo?.id || "",
                popup: editingData.popup ? 1 : 0 || 0,
                popup_titulo: editingData.popup_titulo || "",
                popup_cuerpo: editingData.popup_cuerpo || "",
                logo: editingData.logo || null,
                logo_url: editingData.logo_url || null,
                logo_name: editingData.logo_name || null,
                requisito: editingData?.requisito || "",
              }}
              enableReinitialize={true}
              validationSchema={addFormValidationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                values.activo = 1;
                if (!values.requisito) {
                  delete values.requisito;
                }
                if (editingData.id !== undefined) {
                  // Editing
                  //Edición de Formulario
                  const formvalues = createFormData(values);
                  req
                    .post(
                      `${API_URL}formularios/${editingData.id}/update`,
                      formvalues
                    )
                    .then((response) => {
                      this.state.sections.forEach((row) => {
                        // Creación de Sección
                        if (row.id === undefined) {
                          console.log('procesando el guardado de ')
                          req
                            .post(`${API_URL}formularios/secciones/store`, {
                              formulario: editingData.id,
                              nombre: row.nombre,
                              tipo: row.tipo,
                              orden: row.orden,
                            })
                            .then((response) => {
                              console.log(response);
                            });
                        } else {
                          // Edición de Sección
                          req
                            .post(
                              `${API_URL}formularios/secciones/${row.id}/update`,
                              {
                                formulario: editingData.id,
                                nombre: row.nombre,
                                tipo: row.tipo,
                                orden: row.orden,
                              }
                            )
                            .then((response) => {
                              console.log(response);
                            });
                        }
                      });

                      if (isCreatingQuestion) {
                        this.setState({ leaveValidation: true });
                        this.props.history.push({
                          pathname: `/formulario/pregunta`,
                        });
                      } else {
                        this.setState({ leaveValidation: false });

                        let these = this;
                        setTimeout(function () {
                          these.props.history.push({
                            pathname: "/formularios",
                            state: {
                              alertShow: true,
                              alertMessage: "Formulario Actualizado",
                              alertType: "success",
                            },
                          });
                        }, 500);
                      }
                    }).catch((error) => {
                      $(window).scrollTop(0);
                      this.setState({
                        alertShow: true,
                        alertType: "fail",
                        alertMessage: error.response.data.message,
                      });
                    });
                } else {
                  // Creating
                  req
                    .post(`${API_URL}formularios/store`, values)
                    .then((response) => {
                      sessionStorage.setItem(
                        "formCreation",
                        JSON.stringify(response.data.data.id)
                      );

                      this.state.sections.forEach((row) => {
                        req
                          .post(`${API_URL}formularios/secciones/store`, {
                            formulario: response.data.data.id,
                            nombre: row.nombre,
                            tipo: row.tipo,
                            orden: row.orden,
                          })
                          .then((response) => {
                            console.log(response);
                          });
                      });

                      if (isCreatingQuestion) {
                        this.setState({ leaveValidation: true });
                        this.props.history.push({
                          pathname: `/formulario/pregunta`,
                        });
                      } else {
                        this.setState({ leaveValidation: false });
                        this.props.history.push({
                          pathname: "/formularios",
                          state: {
                            alertShow: true,
                            alertMessage: "Formulario Actualizado",
                            alertType: "success",
                          },
                        });
                      }
                    }).catch((error) => {
                      $(window).scrollTop(0);
                      this.setState({
                        alertShow: true,
                        alertType: "fail",
                        alertMessage: error.response.data.message,
                      });
                    });
                }

                // if (editingData.id !== undefined) {
                //   delete values.id;
                //   req
                //     .post(
                //       `${API_URL}formularios/${editingData.id}/update`,
                //       values
                //     )
                //     .then(() => {
                //       this.setState({
                //         alertShow: true,
                //         alertType: "success",
                //         alertMessage: "El formulario fue actualizado con éxito",
                //       });
                //       this.tableRef.current.onQueryChange({});
                //     });
                // } else {
                //   req
                //     .post(`${API_URL}formularios/store`, values)
                //     .then((response) => {
                //       console.log(...forms);
                //       // this.setState({
                //       //   forms: [...forms, response.data.data],
                //       //   alertShow: true,
                //       //   alertType: 'success',
                //       //   alertMessage: 'El formulario fue creado con éxito'
                //       // });
                //     });
                // }
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col lg="4">
                      <div className="form-group">
                        <label>Nombre</label>
                        <Field type="text" name="nombre" />
                        <ErrorMessage
                          className="errorField"
                          name="nombre"
                          component="p"
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="form-group">
                        <label>Tipo de Encuesta</label>
                        <Field name="tipo" as="select">
                          <option value="null"> Selecciona </option>
                          {formtypes.map((elem) => (
                            <option key={elem.id} value={elem.id}>
                              {elem.nombre}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          className="errorField"
                          name="tipo"
                          component="p"
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="form-group">
                        <label>Formulario requisito</label>
                        {formularioRequisitoSeleccionado !== undefined &&
                          <MultiSelect
                            name="requisito"
                            defaultValue={formularioRequisitoSeleccionado}
                            onChange={(e) => {
                              formik.setFieldValue("requisito", e);
                            }}
                            options={opcionesRequisito}
                          />
                        }
                        {formularioRequisitoSeleccionado == undefined &&
                          <MultiSelect
                            name="requisito"
                            onChange={(e) => {
                              formik.setFieldValue("requisito", e);
                            }}
                            options={opcionesRequisito}
                          />
                        }
                        <ErrorMessage
                          component="p"
                          name="requisito"
                          className="small text-danger"
                        />
                      </div>

                    </Col>
                  </Row>
                  {this.renderPop(formik)}
                  <br />
                  {this.renderSections(formik)}
                  <br />
                  {this.renderQuestions(formik)}
                  <Row>
                    <Col lg="12">
                      <div className="contButtons">
                        <Button
                          type="submit"
                          className="buttonSubmit"
                          variant="primary "
                        >
                          {formik.isSubmitting ? "Guardando..." : "Guardar"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </CSSTransition>
        <Modal
          size="xl"
          centered
          show={popupAddSection}
          onHide={this.hide_PopUpSections}
        >
          <Modal.Header closeButton>
            <Modal.Title>Guardar Sección</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                nombre: editingDataSection.nombre || "",
                orden: editingDataSection.orden || 0,
                tipo: editingDataSection.tipo || 0,
                activo: true,
              }}
              validationSchema={addSectionValidationSchema}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                const limit = pLimit(1);
                const isNew = editingDataSection.id === undefined;
                if (isNew) {
                  this.setState({
                    sections: [...this.state.sections, values],
                  });
                } else {
                  if (fieldId) {
                    const response = await req
                      .post(
                        `${API_URL}formularios/secciones/${editingDataSection.id}/update`,
                        {
                          formulario: fieldId,
                          nombre: values.nombre,
                          tipo: values.tipo,
                          orden: values.orden,
                        }
                      );


                    await Promise.all(dependentfieldsSelected.map(elem => limit(() => this.processDependency(elem, response))));

                    req
                      .get(`${API_URL}formularios/secciones`, {
                        params: {
                          formulario: this.props.match.params.formularyId,
                          paginacion: 999999,
                        },
                      })
                      .then((response) => {
                        this.setState({
                          sections: response.data.data ?? [],
                        });
                      });

                  } else {
                    alert("editando sección sin crear");
                  }
                }
                this.hide_PopUpSections();
                setSubmitting(false);
              }}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col lg="4">
                      <div className="form-group">
                        <label>Nombre</label>
                        <Field type="text" name="nombre" />
                        <ErrorMessage
                          className="errorField"
                          name="name"
                          component="p"
                        />
                      </div>
                    </Col>
                    <FS
                      label="Tipo de Sección"
                      name="tipo"
                      errors={formik.errors}
                      col={[4]}
                    >
                      <Field
                        name="tipo"
                        as="select"
                      >
                        <option value=""> Selecciona </option>
                        <option value="general"> General </option>
                        <option value="segregada"> Segregada </option>
                      </Field>
                    </FS>
                    <Col lg="4">
                      <div className="form-group">
                        <label>Orden</label>
                        <Field type="number" name="orden" />
                        <ErrorMessage
                          className="errorField"
                          name="orden"
                          component="p"
                        />
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg={12}>
                      <strong>Preguntas Dependientes</strong>
                      <div className="subBox">
                        <div className="form-group">
                          <Row>
                            {/* <p style={{ marginBottom: "0" }}>
                              Esta sección se mostrará cuando:
                            </p> */}

                            <FS
                              label="Pregunta"
                              name="dependence_formfield"
                              errors={formik.errors}
                              col={[3]}
                            >
                              <Field
                                name="dependence_formfield"
                                onChange={(e) =>
                                  this.selectDependence_formfield(
                                    e.target.value,
                                    formik
                                  )
                                }
                                ref={this.question}
                                as="select"
                              >
                                <option value=""> Selecciona </option>
                                {questions.map((pregunta) => (
                                  <option key={pregunta.id} value={pregunta.id}>
                                    {` ${pregunta.pregunta} `}
                                  </option>
                                ))}
                              </Field>
                            </FS>
                            <FS
                              label="Operador Relacional"
                              name="dependence_operator"
                              errors={formik.errors}
                              col={[3]}
                            >
                              <Field
                                ref={this.operator}
                                name="dependence_operator"
                                as="select"
                              >
                                <option value=""> Selecciona </option>
                                <option value="0"> Sea Igual a</option>
                                <option value="3"> Sea Diferente a </option>
                              </Field>
                            </FS>
                            <FS
                              label="Respuesta"
                              name="dependence_response"
                              errors={formik.errors}
                              col={[3]}
                            >
                              {this.renderAnswerField(formik)}
                            </FS>

                            <FS
                              label="Operador Lógico"
                              name="dependence_logic_operator"
                              errors={formik.errors}
                              col={[3]}
                            >
                              <Field
                                // ref={this.operator}
                                name="dependence_logic_operator"
                                as="select"
                              >
                                <option value=""> Selecciona </option>
                                <option value="or"> O</option>
                                <option value="and"> Y </option>
                              </Field>
                            </FS>
                            <Col lg={3}>
                              <br />
                              <Button
                                variant="primary "
                                className="secondaryButton"
                                disabled={
                                  !formik.values.dependence_formfield ||
                                  !formik.values.dependence_operator ||
                                  !formik.values.dependence_response ||
                                  !formik.values.dependence_logic_operator
                                }
                                onClick={() =>
                                  this.addDependence(formik.values)
                                }
                              >
                                Añadir
                              </Button>

                            </Col>
                            {/* <Col lg={8}>
                              <label>Al agregar una pregunta dependiente, los operadores lógicos de las dependencias creadas se actualizarán automáticamente.</label>
                            </Col> */}
                          </Row>
                          <hr />
                          <MaterialTable
                            title=""
                            columns={[
                              {
                                title: "Operador",
                                field: "operador",
                                hidden: true,
                              },
                              {
                                title: "Pregunta",
                                field: "form_field_id",
                                editable: false,
                                render: (rowData) =>
                                  formfieldsAll.find(
                                    (elem) => elem.id === rowData.form_field_id
                                  )?.pregunta,
                                customFilterAndSearch: (term, rowData) =>
                                  formfieldsAll
                                    .find(
                                      (elem) =>
                                        elem.id === rowData.form_field_id
                                    )
                                    ?.pregunta?.toLowerCase()
                                    .indexOf(term.toLowerCase()) >= 0,
                              },
                              {
                                title: "Operador",
                                field: "operador",
                                editable: false,
                                render: (rowData) =>
                                  returnRelationalOperator(rowData.operador),
                              },
                              {
                                title: "Respuesta",
                                field: "valor",
                                editable: false,
                                render: rowData => typeof rowData?.respuesta === 'string' ? rowData?.respuesta : rowData.valor,
                                customFilterAndSearch: (term, rowData) =>
                                  rowData?.respuesta || rowData?.valor
                                    .toLowerCase()
                                    .indexOf(term.toLowerCase()) >= 0,
                              },
                              {
                                title: "Operador Lógico",
                                field: "operador",

                                render: (rowData) =>
                                  returnLogicalOperator(rowData.operador),
                              },
                              {
                                title: "Estatus",
                                field: "activo",
                                editable: false,
                                render: (rowData) =>
                                  getTrueFalse(
                                    rowData.activo,
                                    "Activo",
                                    "Inactivo"
                                  ),
                              },
                            ]}
                            options={{
                              search: false,
                              selection: false,
                              searchFieldAlignment: "right",
                              paging: false,
                              pageSize: 20,
                              draggable: false,
                            }}
                            data={dependentfieldsSelected}
                            localization={{
                              header: {
                                actions: "Acciones",
                              },
                            }}
                            actions={[
                              (rowData) => ({
                                icon: DeleteIcon,
                                tooltip: "Eliminar",
                                onClick: (event, rowData) =>
                                  this.setState({
                                    confirmModal: rowData
                                  }),
                                disabled: !this.state.isAdmin
                              }),
                              (rowData) => ({
                                icon: DoNotDisturbOnOutlinedIcon,
                                tooltip: "Desactivar",
                                onClick: (event, rowData) =>
                                  this.toggleDependence(rowData),
                                disabled: rowData.activo === false,
                              }),
                              (rowData) => ({
                                icon: CheckCircleOutlinedIcon,
                                tooltip: "Activar",
                                onClick: (event, rowData) =>
                                  this.toggleDependence(rowData),
                                disabled: rowData.activo === true,
                              }),
                            ]}
                            editable={{
                              onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                  setTimeout(() => {
                                    const dataUpdate = [...dependentfieldsSelected];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    // setData([...dataUpdate]);
                                    this.setState({ dependentfieldsSelected: dataUpdate })

                                    console.log({ newData, dependentfieldsSelected })
                                    if (newData?.originalObject) {
                                      req.post(
                                        `${API_URL}formularios/secciones/dependencias/${newData.id}/update`,
                                        {
                                          formulario_seccion: newData.originalObject.formulario_seccion_id,
                                          formulario_item_dependencia: newData.originalObject.formulario_item_id_dependencia,
                                          operador: newData.operador,
                                          // formulario_respuesta: newData.originalObject.respuesta.id,
                                          valor: newData.originalObject.valor
                                        }
                                      ).finally(() => resolve());
                                    } else {
                                      toast.error("No se pudo actualizar la dependencia ya que debes crearla primero")
                                    }
                                  }, 1000);
                                }),
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="contButtons">
                        <Button
                          type="submit"
                          className="buttonSubmit"
                          disabled={formik.isSubmitting}
                          variant="primary "
                        >
                          {formik.isSubmitting ? "Guardando..." : "Guardar"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Modal
          centered
          show={this.state.showModalRemoveDependence}
          onHide={this.hideModalQuestion}
        >
          <Modal.Header closeButton>
            <Modal.Title>No se puede mover la pregunta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Para poder mover este productos debes remover todas sus dependencias.</p>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          centered
          show={this.state.showMoveQuestion}
          onHide={this.hideModalQuestion}
        >
          <Modal.Header closeButton>
            <Modal.Title>Mover a Formulario</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                formulario_id: '',
                seccion_id: '',
              }}
              validationSchema={Yup.object().shape({
                formulario_id: Yup.string().required(
                  'El formulario es un campo requerido.'
                ),
                seccion_id: Yup.string().required('La sección es un campo requerido.'),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                let question = this.state.questionToMove;

                req
                  .post(`${API_URL}formularios/items/${this.state.questionToMove.id}/update`, {
                    formulario_seccion: values.seccion_id,
                    tipo_pregunta: question.tipo_pregunta.id,
                    orden: question.orden,
                    indice: question.indice,
                    pregunta: question.pregunta,
                    requerido: question.requerido ? 1 : 0,
                    codificacion: question.codificacion
                  })
                  .then((response) => {
                    this.hideModalQuestion();
                    setTimeout(function () {
                      window.location.reload();
                    }, 500);
                  });
                this.hideModalQuestion();
                setSubmitting(false);
              }}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <FS
                      label='Formulario'
                      name='formulario_id'
                      errors={formik.errors}
                      col={[12]}
                    >
                      <Field
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          this.formSelected(formik);
                        }} name='formulario_id' as='select'>
                        <option value='null'> Selecciona </option>
                        {forms.map((p) => (
                          <option value={p.id}> {p.nombre}</option>
                        ))}
                      </Field>
                    </FS>
                    <FS
                      label='Sección'
                      name='seccion_id'
                      errors={formik.errors}
                      col={[12]}
                    >
                      <Field name='seccion_id' as='select'>
                        <option value=''> Selecciona </option>
                        {this.state.sectionsFiltered.map((p) => (
                          <option key={p.id} value={p.id}>
                            {' '}
                            {p.nombre}
                          </option>
                        ))}
                      </Field>
                    </FS>
                    <Col lg="12">
                      <div className="contButtons">
                        <Button
                          type="submit"
                          className="buttonSubmit"
                          disabled={formik.isSubmitting}
                          variant="primary "
                        >
                          {formik.isSubmitting ? "Moviendo..." : "Mover"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </Container>
    );
  }

  renderPop(formik) {
    return (
      <Row lg={12}>
        <Col lg={12}>
          <Row>
            <Col lg={4}>
              <div className="form-group">
                <strong>Lleva Ventana Emergente</strong>
                <Field
                  name="popup"
                  as="select"
                  value={formik.values.popup}
                  onChange={(e) => {
                    formik.setFieldValue("popup", e.target.value);
                  }}
                >
                  <option value="null">Selecciona</option>
                  <option value={1}>Si</option>
                  <option value={0}>No</option>
                </Field>
                <ErrorMessage className="errorField" name="popup" component="p" />
              </div>
            </Col>

            {(formik.values.popup.toString() === "1") && (
              <>
                <Col lg={8}>
                  <div className="form-group">
                    <strong>Título ventana emergente</strong>
                    <textarea
                      name="popup_titulo"
                      value={formik.values.popup_titulo}
                      onChange={(e) => {
                        formik.setFieldValue("popup_titulo", e.target.value);
                      }}
                    />
                  </div>
                </Col>

                <Col lg={12}>
                  <div className="form-group">
                    <strong>Cuerpo de ventana emergente</strong>
                    <textarea
                      name="popup_cuerpo"
                      value={formik.values.popup_cuerpo}
                      onChange={(e) => {
                        formik.setFieldValue("popup_cuerpo", e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Col>

        <Col lg={12}>
          <div className="form-group">
            <p>Archivo de logo del formulario</p>
            <input
              type="file"
              name="file"
              id="file-input"
              onChange={(event) => {
                const reader = new FileReader();
                const file = event.currentTarget.files[0];
                if (file) {
                  reader.onload = () => {
                    formik.setFieldValue('logo_url', reader.result);
                    formik.setFieldValue('logo', reader.result);
                  };
                  reader.readAsDataURL(file);
                }
              }}
            />
            {formik.values.logo_url !== "" ? (
              <Row style={{ marginTop: 20 }}>
                <Col lg="5">
                  <img
                    alt="logo"
                    style={{
                      width: "100%",
                      background: "#dadada",
                      borderRadius: "4px",
                      padding: "8px",
                    }}
                    src={formik.values.logo_url}
                  />
                </Col>
                <Col lg="6">
                  <Button
                    style={{ marginTop: 10 }}
                    onClick={() => {
                      formik.setFieldValue("logo_url", "");
                      document.getElementById("file-input").value = "";
                    }}
                    className="secondaryButton"
                  >
                    Borrar
                  </Button>
                </Col>
              </Row>
            ) : null}
          </div>
        </Col>
      </Row>

    )
  }
  renderAnswerField(formik) {
    const { fieldresponses, dependence_type } = this.state;
    switch (dependence_type.toLowerCase()) {
      case "selección única":
      case "selección múltiple":
        return (
          <Field
            ref={this.answerDependence}
            name="dependence_response"
            as="select"
          >
            <option value=""> Selecciona</option>
            {fieldresponses
              .filter(
                (respuesta) =>
                  respuesta.formulario_item_id ===
                  parseInt(formik.values.dependence_formfield) && respuesta.activo
              ) // TODO remove the filter call
              .map((respuesta) => (
                <option key={respuesta.id} value={respuesta.id}>
                  {" " + respuesta.respuesta + " "}
                </option>
              ))}
          </Field>
        );
      case "si o no":
        return (
          <Field
            ref={this.answerDependence}
            name="dependence_response"
            as="select"
          >
            <option value=""> Selecciona </option>
            <option value="1"> Si </option>
            <option value="0"> No </option>
          </Field>
        );
      case "verdadero o falso":
        return (
          <Field
            ref={this.answerDependence}
            name="dependence_response"
            as="select"
          >
            <option value=""> Selecciona </option>
            <option value="1"> Verdadero </option>
            <option value="0"> Falso </option>
          </Field>
        );

      case "": // no question selected
        return null;
      default:
        return (
          <Field
            ref={this.answerDependence}
            type="text"
            name="dependence_response"
          />
        );
    }
  }
  editRowSections(data) {
    this.show_PopUpSections(data);
  }
  desactivateRowSections(data) {
    data.activo = false;
    req.post(`${API_URL}formularios/secciones/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertType: "success",
        alertMessage: "La Sección ha sido desactivada con éxito",
      });
    });
  }
  activeRowSections(data) {
    data.activo = true;
    req.post(`${API_URL}formularios/secciones/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertType: "success",
        alertMessage: "La Sección ha sido activada con éxito",
      });
    });
  }
  editRowQuestions(data) {
    this.setState({ leaveValidation: false });
    let these = this;
    setTimeout(function () {
      these.props.history.push("/formulario/pregunta/" + data.id);
    }, 500);
  }
  desactivateRowQuestions(data) {
    data.activo = false;
    req.post(`${API_URL}formularios/items/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertType: "success",
        alertMessage: "La Pregunta ha sido desactivada con éxito",
      });
    });
  }
  activeRowQuestions(data) {
    data.activo = true;
    req.post(`${API_URL}formularios/items/${data.id}/toggle`).then(() => {
      this.setState({
        alertShow: true,
        alertType: "success",
        alertMessage: "La Pregunta ha sido activada con éxito",
      });
    });
  }
  renderSections(formik) {
    return (
      <Row>
        <ConfirmationDialog
          onConfirm={() => {
            req.delete(`${API_URL}formularios/secciones/${this.state.confirmModal2.id}/delete`)
              .then(() => {
                toast.success("Sección eliminada correctamente");
                const fieldId = this.props.match.params.formularyId;
                this.fetchSections(fieldId);
              }).catch((error) => {
                toast.error(error.response.data.message)
              }).finally(() => {
                this.setState({ confirmModal2: null });
              })
          }}
          onHide={() => {
            this.setState({ confirmModal2: null });
          }}
          show={!!this.state.confirmModal2}
        >
          <p className='text-center'>
            ¿Te gustaría eliminar esta sección?
          </p>

        </ConfirmationDialog>
        <Col lg={12}>
          <strong>Secciones</strong>
          <div className="subBox">
            <div className="titleButtonsTable">
              <Nav>
                <Nav.Item>
                  <span className="nav-link" onClick={this.show_PopUpSections}>
                    Crear Sección
                  </span>
                </Nav.Item>
              </Nav>
            </div>
            <MaterialTable
              title=""
              tableRef={this.tableRef}
              columns={[
                {
                  title: "id",
                  hidden: "true",
                  defaultSort: "desc",
                  field: "id",
                },
                { title: "Nombre", field: "nombre" },
                { title: "Tipo", field: "tipo" },
                {
                  title: "Estatus",
                  field: "activo",
                  render: (rowData) => checkStatus(rowData.activo),
                },
                { title: "Orden", field: "orden" },
              ]}
              actions={[
                {
                  icon: ModeEditOutlinedIcon,
                  tooltip: "Editar",
                  onClick: (event, rowData) => {
                    this.editRowSections(rowData)
                  },
                },
                (rowData) => ({
                  icon: DoNotDisturbOnOutlinedIcon,
                  tooltip: "Desactivar",
                  onClick: (event, rowData) =>
                    this.desactivateRowSections(rowData),
                  disabled: rowData.activo === false,
                }),
                (rowData) => ({
                  icon: CheckCircleOutlinedIcon,
                  tooltip: "Activar",
                  onClick: (event, rowData) => this.activeRowSections(rowData),
                  disabled: rowData.activo === true,
                }),
                (rowData) => ({
                  icon: HistoryIcon,
                  tooltip: "Auditoria",
                  onClick: (event, rowData) =>
                    this.showAudit(rowData.id, "formularios/secciones"),
                }),
                (rowData) => ({
                  icon: DeleteOutlineIcon,
                  tooltip: "Eliminar",
                  onClick: (event, rowData) =>
                    this.setState({
                      confirmModal2: rowData
                    }),
                  disabled: !this.state.isAdmin
                })
              ]}
              localization={{
                header: {
                  actions: "Acciones",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                pagination: {
                  previousTooltip: "Página anterior",
                  nextTooltip: "Página siguiente",
                  firstTooltip: "Primera página",
                  lastTooltip: "Última página",
                },
                body: {
                  emptyDataSourceMessage: "No hay registros que mostrar",
                },
              }}
              options={{
                search: true,
                selection: false,
                searchFieldAlignment: "right",
                actionsColumnIndex: -1,
                paging: true,
                emptyRowsWhenPaging: false,
                pageSize: 20,
                filtering: false,
                draggable: false,
              }}
              data={this.state.sections}
            />
          </div>
        </Col>
      </Row>
    );
  }
  createQuestion(formik) {
    this.setState({ isCreatingQuestion: true });
    formik.submitForm();
    // if(formik.)
    // let = [
    //   form:
    // ]
    //  sessionStorage.setItem("formCreation", JSON.stringify(data));
  }

  renderQuestions(formik) {

    return (
      <Row>
        <ConfirmationDialog
          onConfirm={() => {
            req.delete(`${API_URL}formularios/items/${this.state.confirmModal3.id}/delete`)
              .then(() => {
                const fieldId = this.props.match.params.formularyId;
                this.fetchQuestions(fieldId);
                toast.success('Pregunta eliminada correctamente');
              }).catch((error) => {
                toast.error(error?.response?.data?.message || 'Error al eliminar la pregunta');
              }).finally(() => {
                this.setState({ confirmModal3: false });
              })
          }}
          onHide={() => {
            this.setState({ confirmModal3: false });
          }}
          show={!!this.state.confirmModal3}
        >
          <p className='text-center'>
            ¿Te gustaría eliminar la pregunta?
          </p>

        </ConfirmationDialog>
        <Col lg={12}>
          <strong>Preguntas</strong>
          <div className="subBox">
            <div className="titleButtonsTable">
              <Nav>
                <Nav.Item>
                  {this.state.sections.length !== 0 ? (
                    <span
                      className="nav-link"
                      onClick={() => this.createQuestion(formik)}
                    >
                      Crear Pregunta
                    </span>
                  ) : (
                    <p
                      style={{
                        fontSize: "14px",
                        marginTop: "11px",
                        fontWeight: 400,
                        color: "#ff4800",
                      }}
                    >
                      Para crear una pregunta debes agregar al menos una sección
                    </p>
                  )}
                </Nav.Item>
              </Nav>
            </div>
            {!!this.state.loadingQuestions ? <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 40,
                marginBottom: 20
              }}
            > <CircularProgress /> </div> : (
              <MaterialTable
                tableRef={this.tableRef}
                title=""
                columns={[
                  {
                    title: "id",
                    hidden: "true",
                    filtering: false,
                    field: "id",
                  },
                  {
                    title: "Titulo",
                    field: "pregunta",
                    filtering: false,
                    width: "40%",
                  },
                  {
                    title: "Tipo de Pregunta",
                    field: "tipo_pregunta",
                    filtering: false,
                    render: (rowData) => (
                      <span>{rowData.tipo_pregunta.nombre}</span>
                    ),
                    customFilterAndSearch: (term, rowData) =>
                      rowData.tipo_pregunta.nombre
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) >= 0,
                  },
                  {
                    title: "Requerido",
                    field: "requerido",
                    filtering: false,
                    render: (rowData) =>
                      getTrueFalse(
                        rowData.requerido,
                        "Requerido",
                        "No Requerido"
                      ),
                    },
                    {
                      title: "Sección",
                      field: "section",
                      render: (rowData) => rowData.seccion.nombre,
                      defaultSort: "asc",
                      customSort: (a, b) => a.seccion.nombre.localeCompare(b.seccion.nombre),
                      customFilterAndSearch: (term, rowData) =>
                        rowData.seccion.nombre
                      .toLowerCase()
                        .indexOf(term.toLowerCase()) !== -1,
                      },
                  {
                    title: "Estatus",
                    field: "activo",
                    filtering: false,
                    render: (rowData) => checkStatus(rowData.activo),
                  },
                  {
                    title: "Orden",
                    field: "orden",
                    filtering: false,
                    width: "5%",
                  },
                ]}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                  pagination: {
                    previousTooltip: "Página anterior",
                    nextTooltip: "Página siguiente",
                    firstTooltip: "Primera página",
                    lastTooltip: "Última página",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay registros que mostrar",
                  },
                }}
                options={{
                  search: true,
                  selection: false,
                  searchFieldAlignment: "right",
                  paging: true,
                  pageSize: 30,
                  emptyRowsWhenPaging: false,
                  sorting: true,
                  filtering: true,
                  actionsColumnIndex: -1,
                  draggable: false,
                }}
                data={this.state.questions}
                actions={[
                  {
                    icon: ModeEditOutlinedIcon,
                    tooltip: "Editar",
                    onClick: (event, rowData) => this.editRowQuestions(rowData),
                  },
                  (rowData) => ({
                    icon: DoNotDisturbOnOutlinedIcon,
                    tooltip: "Desactivar",
                    onClick: (event, rowData) =>
                      this.desactivateRowQuestions(rowData),
                    disabled: rowData.activo === false,
                  }),
                  (rowData) => ({
                    icon: CheckCircleOutlinedIcon,
                    tooltip: "Activar",
                    onClick: (event, rowData) => this.activeRowQuestions(rowData),
                    disabled: rowData.activo === true,
                  }),
                  (rowData) => ({
                    icon: HistoryIcon,
                    tooltip: "Auditoria",
                    onClick: (event, rowData) =>
                      this.showAudit(rowData.id, "formularios/items"),
                  }),
                  (rowData) => ({
                    icon: MoveUpIcon,
                    tooltip: "Mover",
                    onClick: (event, rowData) =>
                      this.showMoveQuestion(rowData.id),
                  }),
                  (rowData) => ({
                    icon: DeleteOutlineIcon,
                    tooltip: "Eliminar",
                    onClick: (event, rowData) =>
                      this.setState({
                        confirmModal3: rowData
                      }),
                    disabled: !this.state.isAdmin
                  })
                ]}

              />
            )}
          </div>
        </Col>
      </Row>
    );
  }
}
export function returnRelationalOperator(value) {
  const relationalOperator = value.split("|")[0];
  switch (relationalOperator) {
    case "0":
      return "Sea Igual a";
    case "1":
      return "Sea Menor o igual a";
    case "2":
      return "Sea Mayor o igual a";
    case "3":
      return "Sea Diferente a";
    default:
      return "Operador inválido";
  }
}

export function returnLogicalOperator(value) {
  const logicalOperator = value.split("|")[1];

  switch (logicalOperator) {
    case "or":
      return "O";
    case "and":
      return "Y";
    default:
      return 'Y';
  }
}

const createFormData = (poll) => {
  const formData = new FormData();
  Object.keys(poll).forEach((key) => {
    formData.set(key, poll[key]);
  });
  if (poll.logo && poll.logo.startsWith('data:image')) {
    const imgBlob = base64ToBlob(poll.logo);
    formData.set('logo', imgBlob);
  } else if (poll.logo === '') {
    formData.set('logo', null);
  } else {
    formData.delete('logo');
  }
  return formData;
}
export default withRouter(PollFormsCreateEdit);
